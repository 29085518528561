/* ==============
  General
===================*/

body {
  background: $bg-body;
  font-family: $font-primary;
  margin: 0;
  font-size: $base-font;
  color: $body-text;
}
html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  background: $bg-body;
}
h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  color: darken($gray-800, 10%);
}

h1 {
  line-height: 43px;
}

h2 {
  line-height: 35px;
}

h3 {
  line-height: 30px;
  small {
    color: $gray-300;
  }
}

h4 {
  line-height: 22px;
  small {
    color: $gray-300;
  }
}

h5 {
  small {
    color: $gray-300;
  }
}

b {
  font-weight: 500;
}

* {
  outline: none !important;
}

a {
  color: $gray-900;
  &:hover {
    outline: 0;
    text-decoration: none;
    color: darken($gray-900, 20%);
  }
  &:active {
    outline: 0;
    text-decoration: none;
  }
  &:focus {
    outline: 0;
    text-decoration: none;
  }
}
p{
  font-size: 13px;
}
code {
  color: $info;
}


.container-alt {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.footer {
  background-color: rgba($primary,0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  bottom: 0;
  color: $dark;
  text-align: center;
  padding: 20px 30px;
  position: absolute;
  right: 0;
  left: 240px;
}

#wrapper {
  height: $height;
  overflow: hidden;
  width: $width;
}

.page {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.btn-link:hover,
.btn-link:focus{
  text-decoration: none !important;
}

/*Range*/
.rating-symbol-foreground{
  bottom: 0;
}

/* Social */
.social-links {
  li {
    a {
      -webkit-border-radius: 50%;
      background: lighten($light,4%);
      border-radius: 50%;
      color: lighten($gray-800,15%);
      display: inline-block;
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 30px;
    }
  }
}

.h-300{
  height: 300px !important;
}