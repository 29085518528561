@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');


// Color variables

$primary:       #605daf;
$secondary:     #aaaece;
$success:       #5dd099;
$info:          #33cdff;
$warning:       #f8c955;
$danger:        #f96e5b;
$light:         #f5f5f7;
$white:         #ffffff;
$dark:          #2d3b48;
$muted:         #8c9ea9;
$pink:          #f5468e;
$purple:        #775fd5;


$gray-100:      #f6f6f9;
$gray-200:      #f3f2f7;
$gray-300:      #d4d8e1;
$gray-400:      #bcc1c2;
$gray-500:      #a3a7a8;
$gray-600:      #939798;
$gray-700:      #78797c;
$gray-800:      #636466;
$gray-900:      #292a2f;



$bg-body:       #f5f5f5;
$body-text:     #333265;
$left-bar:      #ffffff;
$bg-topbar:     #605daf;

$table-text:    #8887a9;

$font-primary: 'Rubik', sans-serif;

$shadow: 2px 0px 3px rgba(96,93,175,0.05);
$shadow-md: 1px 0px 20px rgba(96,93,175,0.05);

$base-font: 14px;



// Width variables
$width: 100%;


// Height variables
$height: 100%;


