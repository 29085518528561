/* ==============
 Demo Only css
===================*/


.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}

.icon-demo-content {
  text-align: center;
  color: $muted;

  i{
    display: block;
    font-size: 28px;
    margin-bottom: 5px;
  }

  .col-sm-6 {
    margin-bottom: 30px;

    &:hover {
      i {
        color: $primary;
      }
    }
  }
}

/*===grid===*/

.grid-col div span{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  background: $light;
  border: 1px solid;
  border-color: $gray-400;
  display: block;
}
.grid-col .nested-col.row span{
  background-color: $light;
  display: block;
}