/* ==============
  Range slider
===================*/
.irs-from, .irs-to, .irs-single {
  background: $primary;
}
.irs-from:after, .irs-to:after, .irs-single:after {
  border-top-color: $primary;
}

/* ==============
  Powerange Slider
===================*/
.range-bar{
  background-color: $gray-300;
}

.range-min, .range-max {
  color: $muted;
  padding-top: 0;
}
.range-quantity {
  background-color: $primary;
}

.slider-wrapper {
  margin: 0 25px 12px;
  position: relative;
  width: 250px;
  &.vertical-wrapper{
    height: 200px;
    width: auto;
  }
}
.display-box-label {
  font-size: 0.8em;
  position: absolute;
  right: -80px;
  top: -8px;
}

.display-box {
  background-color: $primary;
  color: $white;
  border-radius: 5px;
  font-size: 0.8em;
  height: 23px;
  right: -140px;
  padding-top: 2px;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 50px;
  box-shadow: inset 0 0 10px rgba($gray-500, 0.15);
}

