/* ==============
  Bootstrap-custom
===================*/

// Breacrumb
.breadcrumb {
  background-color: transparent;
  margin-bottom: 15px;
  margin-top: 5px;
}

//Dropdown
.dropdown-menu {
  padding: 4px 0;
  font-size: 15px;
  box-shadow: 0 2px 30px rgba($gray-600,.08);
  border-color: $light;
}

.dropdown-item.active, .dropdown-item:active {
  color: $dark;
  text-decoration: none;
  background-color: $light;
}

.dropdown-item {
  padding: .55rem 1.5rem;
}


//Background color
.bg-primary {
  background-color: $primary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-muted {
  background-color: $muted !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-purple {
  background-color: $purple !important;
}

//Text Color
.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-warning {
  color: $warning !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-dark {
  color: $dark !important;
}

.text-pink {
  color: $pink !important;
}
.text-purple {
  color: $purple !important;
}


//Badge

.badge {
  font-weight: 500;
}

.badge-default {
  background-color: $light;
  color: $dark;
}

.badge-primary {
  background-color: $primary;
}

.badge-success {
  background-color: $success;
}

.badge-info {
  background-color: $info;
}

.badge-warning {
  background-color: $warning;
  color: $light;
}

.badge-danger {
  background-color: $danger;
}

.badge-pink {
  background-color: $pink;
}

.badge-purple {
  background-color: $purple;
}

.badge-dark {
  background-color: $dark;
}

/* Soft-Budge*/

.badge-soft-primary {
  background-color: rgba($primary, 0.2);
  color: $primary;
}

.badge-soft-success {
  background-color: rgba($success, 0.2);
  color: $success;
}

.badge-soft-info {
  background-color: rgba($info, 0.2);
  color: $info;
}

.badge-soft-warning {
  background-color: rgba($warning, 0.2);
  color: $warning;
}

.badge-soft-danger {
  background-color: rgba($danger, 0.2);
  color: $danger;
}

.badge-soft-pink {
  background-color: rgba($pink, 0.2);
  color: $pink;
}

.badge-soft-purple {
  background-color: rgba($purple, 0.2);
  color: $purple;
}

/* Navs & Tabs */
.nav-pills .nav-item.show .nav-link, .nav-pills .nav-link.active {
  background-color: $primary;
}

.nav-pills > .active > a > .badge {
  color: $primary;
}

/* List Group */
.list-group-item.active {
  background-color: $gray-300;
  border-color: $gray-300;
  color: $gray-400;
  z-index: 2;
  &:hover {
    background-color: $gray-300;
    border-color: $gray-300;
    color: $gray-400;
    z-index: 2;
    .list-group-item-text {
      color: $primary;
    }
  }
  &:focus {
    background-color: $gray-300;
    border-color: $gray-300;
    color: $gray-400;
    z-index: 2;
    .list-group-item-text {
      color: $primary;
    }
  }
  .list-group-item-text {
    color: $primary;
  }
}

.list-group-item {
  border-radius: 0;
  padding: 12px 20px;
  border: 1px solid fade($dark,6%);
  &:first-child {
    border-radius: 0;
    padding: 12px 20px;
  }
  &:last-child {
    border-radius: 0;
    padding: 12px 20px;
  }
}

.list-group-item-heading {
  font-weight: 300;
}

.list-group-item.active > .badge {
  color: $primary;
}

// checkbox

.custom-checkbox {
  .custom-control-input:checked~.custom-control-label::before {
    background-color: $primary;
  }
}

// radio button

.custom-radio {
  .custom-control-input:checked~.custom-control-label::before {
    background-color: $primary;
  }
  .custom-control-input:disabled:checked~.custom-control-label::before{
    background-color: rgba($primary, 0.5);
  }
}


// Popover
.popover-header {
  margin-top: 0;
}

// Blockquote
.blockquote {
  font-size: 1.05rem;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid $gray-200;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-200;
  text-align: right;
}

//Modal
.modal-title {
  margin-top: 0;
}
