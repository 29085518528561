/* ==============
  Dashboard-2
===================*/

.icon-contain{
  i{
    font-size: 32px;
  }
}


.accordion {
  box-shadow: none;
  .card,
  .btn:focus{
    box-shadow: none;
  }
  .card-body{
    border:1px solid rgba($gray-400, .2); 
  }
  .card-header{
    background-color: rgba($gray-300, .2);
    border: 1px solid rgba($gray-400, .2);
    padding: 0 12px;
  } 
  .btn{
    margin-bottom: 0;
  }
}





.blog-card h4{
  font-weight: 600;
}
.card-img-overlay{
  bottom: auto;
}

.round-icon{
  font-size: 22px;
  width: 44px;
  height: 44px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
}



/*Timeline*/

.timeline-card{
  overflow: hidden;
}

.timeline {
  width:100%;
  max-width:800px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left:calc(25% + 0px); //$gutter/2
    bottom: 0px;
    width: 4px;
    background: $light;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  } 
}

.entry {
  clear: both;
  text-align: left;
  position: relative;
  .title {
    margin-bottom: .5em;
    float: left;
    width: 25%;
    padding-right: 30px;
    text-align: right;
    position: relative;
    h6{
      font-size: 16px;
      color: $muted;
      font-weight: 400;
      margin-top: 0px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border: 4px solid $white;
      background-color:$info;
      border-radius:100%;
      top: 8%;
      right: -10px;
      z-index: 99;
      box-shadow: 0px 0px 0px 1.25px $success;
    }
    
    p {
      margin: 0;
      font-size: 100%;
    }
  }
  .body {
    margin: 0 0 42px;
    float: right;
    width: 75%;
    padding-left: 30px;
    
    p {
      line-height: 1.4em;
      color: $muted;
      font-size: 14px;
      &:first-child {
        margin-top: 0;
        font-weight: 400;
      }
      
    }    
  }
}
